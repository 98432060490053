import React from 'react'
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Bio from '../components/Bio'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import { rhythm } from '../utils/typography'

import icon from '../../static/favicon.ico'

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Helmet
          link={[
            {
              rel: 'shortcut icon',
              type: 'image/png',
              href: `${icon}`,
            },
          ]}
        >
          <title>Software Engineering Books - Alex Kondov</title>
          <meta
            property="og:image"
            content={`https://alexkondov.com${this.props.data.cover.childImageSharp.fluid.src}`}
          />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <meta
            property="og:title"
            content="Alex Kondov's Personal Blog"
          />
          <meta
            property="og:description"
            content="Thoughts on programming, writing and the things in between."
          />
          <meta property="og:url" content="https://alexkondov.com" />
          <meta
            name="twitter:card"
            content="summary_large_image"
          ></meta>
          <meta property="og:type" content="website" />
          <meta property="og:locale" content="en_US" />
        </Helmet>
        <h2 className="section-title">Books</h2>
        <a target="_blank" href="https://fullstacktao.com">
          <div className="book">
            <img
              style={{ borderRadius: 3, border: '1px solid #ccc', padding: 5, backgroundColor: '#fff' }}
              src={data.thirdBookCover.childImageSharp.fluid.src}
            />
            <div>
              <h2 style={{ marginTop: 10, fontWeight: 400 }}>
                Full Stack Tao
              </h2>
              <div className="divider"></div>
              <p>
                Everything I've learned about building software in the last 10 years.
              </p>
            </div>
          </div>
        </a>
        <a target="_blank" href="https://taoofnode.com">
          <div className="book">
            <img
              style={{ borderRadius: 3 }}
              src={data.secondBookCover.childImageSharp.fluid.src}
            />
            <div>
              <h2 style={{ marginTop: 10, fontWeight: 400 }}>
                Tao of Node
              </h2>
              <div className="divider"></div>
              <p>
                Learn how to build better Node.js applications. A
                collection of best practices about architecture,
                tooling, performance and testing.
              </p>
            </div>
          </div>
        </a>
        <a target="_blank" href="https://taoofreact.com">
          <div className="book">
            <img
              style={{ borderRadius: 3 }}
              src={data.bookCover.childImageSharp.fluid.src}
            />
            <div>
              <h2 style={{ marginTop: 10, fontWeight: 400 }}>
                Tao of React
              </h2>
              <div className="divider"></div>
              <p>
                Learn proven practices about React application
                architecture, component design, testing and
                performance.
              </p>
            </div>
          </div>
        </a>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    bookCover: file(
      absolutePath: { regex: "/tao-of-react-new-cover.png/" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    thirdBookCover: file(
      absolutePath: { regex: "/full-stack-tao-cover.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    secondBookCover: file(
      absolutePath: { regex: "/tao-of-node-new-cover.png/" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    cover: file(absolutePath: { regex: "/cover.png/" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
